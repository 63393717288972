html, body {
    background: #010204;
}
#page {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-position: center;
    background-image: url('/img/fwcbrt-bkg.jpg');
    background-size: auto 110%;
    img {
        max-width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media(min-width: 960px) {
            max-width: 759px;
        }
    }
}
